

































import { stateModule, userModule } from '@/store'
import { Component, Vue }          from 'vue-property-decorator'

import Loader    from '@/components/loaders/Loader.vue'
import TextInput from '@/components/inputs/Text.vue'

import { ChangeExpiredPasswordRequest } from '@/requests/auth/ChangeExpiredPasswordRequest'

@Component({
  components: {
    Loader,
    TextInput
  }
})
export default class Expired extends Vue {

  private get loading(): boolean {
    return stateModule.loading
  }
  private submitted: boolean = false

  private page: string = 'expired'

  private form: ChangeExpiredPasswordRequest = {
    username: '',
    current_password: '',
    password: '',
    repeat_password: ''
  }
  private errors: ErrorResponse = {}

  private mounted(): void {
    if (this.$route.query.username) {
      this.form.username = this.$route.query.username.toString()
    } else {
      this.$router.push({ name: 'login' })
    }
  }

  private destroyed(): void {
    if (this.submitted) {
      stateModule.setLoading(false)
    }
  }

  private resetError(key: string): void {
    this.$set(this.errors, key, [])
  }

  private async submit(): Promise<void> {
    stateModule.setLoading(true)
    this.$set(this, 'errors', {})
    try {
      await userModule.renew({ form: this.form })
      this.submitted = true
      stateModule.setNotification({ message: 'Password renewed succesfully. Please log in with your new credentials', type: 'success' })
      await this.$router.push({ name: 'login' })
    } catch (e) {
      stateModule.setLoading(false)
      if (e.errors) {
        this.$set(this, 'errors', e.errors)
      }
    }
  }

}
